import React from 'react';
import clsx from 'clsx';

import styles from './Modal.module.scss';

type OverlayProps = {
    isVisible: boolean;
};
  
const Overlay: React.FC<OverlayProps> = ({ children, isVisible }) => (
    <div className={clsx(styles.overlay, { [styles['is-visible']]: isVisible })}>
        {children}
    </div>
);

type ModalCloseIconProps = {
    onClick: (event:React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const ModalCloseIcon:React.FC<ModalCloseIconProps> = ({ onClick }) => (
    <div className={styles.close} onClick={onClick} />
)

type ModalProps = {
    className?:string;
    isOpen:boolean;
    maxWidth:number;
    onClose: () => void;
}

export const Modal:React.FC<ModalProps> = ({ className, isOpen, maxWidth, onClose, children }) => (
    <Overlay isVisible={isOpen}>
        <div
            className={clsx(
                styles.modal,
                className,
                { [styles['is-open']]: isOpen },
            )}
            style={{ maxWidth  }}
        >
            <ModalCloseIcon onClick={onClose} />
            {children}
        </div>
    </Overlay>
)