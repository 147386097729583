import { http } from 'api/http';
import { ResponseDataType } from 'api/types';
import { AuctionPagination, AuctionType } from 'shared/types';

const getAll = (type: AuctionType): Promise<ResponseDataType<AuctionPagination>> => {
    return http.get(`/auctions/list/${type}/` /* ?page_size=12/ */);
};

const loadMore = async (url: string): Promise<AuctionPagination | null> => {
    const { data } = await http.get<AuctionPagination>(url);

    return data;
};

const remove = (id: string) => {
    http.remove(`/auctions/delete/${id}/`);
};

const create = async (result: any) => {
    await http.post('/auctions/create/', result);
};

const getById = async (id: string) => {
    const { data } = await http.get(`/auctions/retrieve/${id}`);
    return data;
};

const update = async (result: any, id: string) => {
    await http.patch(`/auctions/update/${id}/`, result);
};

export const createAuctionService = () => ({
    getAll,
    loadMore,
    remove,
    create,
    getById,
    update,
});
